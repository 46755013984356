import {initSectionsWithIntersection} from '@scripts/helpers/block.js';

const variant1 = async (section) => {
  const container = section.querySelector('.b-cta__anim');
  const wrapper = section.querySelector('.b-cta__anim-wrapper');
  const circles = Array.from(section.querySelectorAll('.b-cta__anim-circle'));

  const {gsap} = await import('gsap');
  const {ScrollTrigger} = await import('gsap/ScrollTrigger');

  gsap.registerPlugin(ScrollTrigger);

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: container,
      start: 'top center',
      end: 'bottom center',
      scrub: true,
    },
    ease: 'linear',
  });

  const circleAnim = (tl, circle, delay) => {
    tl.fromTo(
      circle,
      {
        clipPath: 'circle(0% at 50% 50%)',
      },
      {
        duration: 1,
        clipPath: 'circle(100% at 50% 50%)',
        ease: 'Power3.out',
      },
      delay,
    );
  };

  circleAnim(timeline, circles[0], 0);
  circleAnim(timeline, circles[1], 0.1);
  circleAnim(timeline, circles[2], 0.16);
  circleAnim(timeline, circles[3], 0.2);

  timeline.fromTo(
    wrapper,
    {
      clipPath: 'inset(0 round 0rem)',
    },
    {
      clipPath: 'inset(2.4rem round 3.2rem)',
      ease: 'Power3.out',
      duration: 0.5,
    },
    0.7,
  );
};
const initializeBlock = async (section) => {
  if (section.classList.contains('is-variant-1')) {
    await variant1(section);
  }
};

initSectionsWithIntersection(async () => {
  return Array.from(document.getElementsByClassName('b-cta'));
}, initializeBlock);

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
